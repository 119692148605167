import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/rajivchelladurai.com/next.js/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/sumeet/webdesign/rajivchelladurai.com/next.js/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/rajivchelladurai.com/next.js/src/app/Blogger.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/rajivchelladurai.com/next.js/src/components/Counter.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/rajivchelladurai.com/next.js/src/components/Instagram.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/rajivchelladurai.com/next.js/src/components/Latestvideo.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/rajivchelladurai.com/next.js/src/components/Slider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/sumeet/webdesign/rajivchelladurai.com/next.js/src/components/Testimonials.jsx");
